@media print {
  body * {
    visibility: hidden;
  }
  #printable, #printable * {
    visibility: visible;
  }

  #noprintable, #noprintable * {
    visibility: hidden;
  }
  #printable {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #FFF;
  }
}