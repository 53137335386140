@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
@import url("https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css");

*{
    margin: 0;
    padding: 0;
}

body{

    font-family: 'Open Sans', sans-serif;
    font-family: Nunito,sans-serif;
    font-size: .8rem;
    font-weight: 400;
    background: #535252;
    
}



.tool-bar{
    /* box-shadow: inset 0 0 1em #c666ec, 0 0 1em #222f3e; */
    background-color: #7b2539;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 100px;
    z-index: 1000;
}

.sidebar{

    position: fixed;
    background-color: #7b2539;
    top: 0;
    left: 0;
    height: 100%;
    width: 280px;
    transform: translateX(-100%);
    transition: all 0.4s;
    border-top-right-radius: 50px;  
    border-bottom-right-radius: 30px;  
    z-index: 1000;


}

.sidebar li{
    
    list-style: none;
    padding: 1rem 2rem;
    border-bottom: solid rgba(255,255,255,0.1) 1px;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 16px;
    letter-spacing: 1.8px;
    word-spacing: 1.8px;
    font-family: "Arial", Monaco, monospace;
    color: #FFFFFF;
    font-weight: 500;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
}

.dropdown-menu{
    background-color: #b66b7d;
    width: 100%;
    color: #FFF

}

.list-unstyled{
    color: #FFF
}

/* .dropdown-menu :hover{
    background-color: #03dac6;
} */

.sidebar--open{
    transform: translateX(0%);

}

.sidebar li:hover{
    background-color: #03dac6;
    color: #900052;

}



.backdrop{
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.5);
    display: none;
 }
 
 .backdrop--open{
 
     display: block;
 }
 