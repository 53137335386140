.outer {
  width: 100%;
  background-image: url('../../img/bg01.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}


.inner2 {
  width: 450px;
  margin: auto;
  background: #540a01;
  opacity: 0.8;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}


.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.form-group {
  margin-top: 15px;
}

.btnSend {
  margin-top: 10px;
}

.labelForm {
  color: #ffffff;
}